<template>
  <SuccessBanner
    :key="key"
    :msg="successMsg"
    :active="activeBanner"
    @closeBanner="activeBanner = false"
    :isSuccess="isSuccess"
  />
  <div class="bg-white shadow m-10 sm:rounded-lg divide-y divide-gray-300">
    <div
      class="-ml-4 -mt-0 flex justify-between items-center flex-wrap sm:flex-nowrap py-2 px-4"
    >
      <div class="ml-4 mt-2">
        <h3 class="text-lg leading-6 font-medium body__header--text capitalize">
          Ticket statuses
        </h3>
        <!-- <p class="mt-1 text-sm text-gray-500">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit quam
                    corrupti consectetur.
                  </p> -->
      </div>
      <div class="ml-1 flex-shrink-0">
        <button
          @click="openForm"
          type="button"
          class="relative inline-flex items-center px-1 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white body__button focus:outline-none "
        >
          <PlusSmIcon class="h-5 w-5" aria-hidden="true" />
        </button>
        <!-- <SearchFilter
          v-model:search="searchOpenTicket"
          :inputPlaceholder="$t('header.searchTicketPlaceHolder')"
        /> -->
      </div>
    </div>
    <div v-if="showForm">
      <EditTicketStatus
        @closeForm="this.showForm = false"
        @activeBanner="(activeBanner = true), getStatus(), (key = !key)"
        @successMsg="successMsg = $event"
        @success="isSuccess = $event"
      />
    </div>
    <TablePagination
      rows="20"
      :tableData="statuses"
      @activeBanner="(activeBanner = true), getStatus(), (key = !key)"
      @successMsg="successMsg = $event"
      @success="isSuccess = $event"
    />
  </div>

  <loading
    v-model:active="isLoading"
    :can-cancel="false"
    :on-cancel="onCancel"
    :is-full-page="fullPage"
  />
</template>

<script>
import axios from "axios";

import TablePagination from "../../../components/TablePagination.vue";
import SuccessBanner from "../../../components/SuccessBanner.vue";
import EditTicketStatus from "../../../components/configuration/support/EditTicketStatus.vue";

import { PlusSmIcon } from "@heroicons/vue/solid";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    PlusSmIcon,
    TablePagination,
    Loading,
    SuccessBanner,
    EditTicketStatus,
  },
  data() {
    return {
      statuses: [],
      activeBanner: false,
      successMsg: "",
      isSuccess: false,
      isLoading: true,
      fullPage: false,
      showForm: false,
      key: false,
    };
  },
  methods: {
    async getStatus() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/statuses`
        );
        this.statuses = res.data;
        this.isLoading = false;
      } catch (error) {
        this.errorHandling(error);
        this.isLoading = false;
      }
    },
    openForm() {
      this.showForm = !this.showForm;
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    this.getStatus();
  },
};
</script>

<style></style>
